import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail"
  }, [_c("div", {
    staticClass: "module"
  }, [_c("p", {
    staticClass: "title pl-20"
  }, [_vm._v("基本信息")]), _c("div", {
    staticClass: "pl-20 pr-20 self-table"
  }, [_c("ul", _vm._l(_vm.columns, function (item) {
    return _c("li", {
      key: item.key
    }, [item.key == "headimgurl" ? _c("cardItem", {
      attrs: {
        label: item.label,
        value: _vm.detailData[item.key]
      },
      scopedSlots: _vm._u([{
        key: "value",
        fn: function fn() {
          return [_c("div", {
            staticClass: "relative"
          }, [_c("img", {
            staticStyle: {
              width: "80px",
              height: "80px",
              "border-radius": "6px"
            },
            attrs: {
              src: _vm.detailData[item.key]
            }
          }), _vm.detailData.icon ? _c("a-avatar", {
            staticClass: "channel-icon",
            attrs: {
              size: "small",
              src: _vm.detailData.icon
            }
          }) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    }) : item.key == "name" ? _c("cardItem", {
      attrs: {
        label: item.label,
        value: _vm.detailData[item.key]
      },
      scopedSlots: _vm._u([{
        key: "value",
        fn: function fn() {
          return [_c("a-tooltip", {
            scopedSlots: _vm._u([{
              key: "title",
              fn: function fn() {
                return [_vm._v(_vm._s(_vm.detailData[item.key]))];
              },
              proxy: true
            }], null, true)
          }, [_c("span", {
            staticClass: "ellipsis-2"
          }, [_vm._v(_vm._s(_vm.detailData[item.key]))])])];
        },
        proxy: true
      }], null, true)
    }) : _c("cardItem", {
      attrs: {
        label: item.label,
        value: _vm.detailData[item.key]
      }
    })], 1);
  }), 0), _c("ul", _vm._l(_vm.columnsTwo, function (item) {
    return _c("li", {
      key: item.key
    }, [item.key == "recommendswitch" ? _c("cardItem", {
      attrs: {
        label: item.label,
        value: _vm.detailData[item.key]
      },
      scopedSlots: _vm._u([{
        key: "value",
        fn: function fn() {
          return [_c("span", [_vm._v(_vm._s(_vm.detailData[item.key] == 1 ? "是" : "否"))])];
        },
        proxy: true
      }], null, true)
    }) : item.key == "ownername" ? _c("cardItem", {
      attrs: {
        label: item.label,
        value: _vm.detailData[item.key]
      },
      scopedSlots: _vm._u([{
        key: "value",
        fn: function fn() {
          return [_c("a", {
            attrs: {
              href: "/user/detail?uid=" + _vm.detailData["ownerid"],
              target: "_blank"
            }
          }, [_vm._v(_vm._s(_vm.detailData[item.key]))])];
        },
        proxy: true
      }], null, true)
    }) : item.key == "description" ? _c("cardItem", {
      attrs: {
        label: item.label,
        value: _vm.detailData[item.key]
      },
      scopedSlots: _vm._u([{
        key: "value",
        fn: function fn() {
          return [_c("a-tooltip", {
            scopedSlots: _vm._u([{
              key: "title",
              fn: function fn() {
                return [_vm._v(_vm._s(_vm.detailData[item.key]))];
              },
              proxy: true
            }], null, true)
          }, [_c("span", {
            staticClass: "ellipsis-2"
          }, [_vm._v(_vm._s(_vm.detailData[item.key]))])])];
        },
        proxy: true
      }], null, true)
    }) : item.key == "labels" ? _c("cardItem", {
      attrs: {
        label: item.label
      },
      scopedSlots: _vm._u([{
        key: "value",
        fn: function fn() {
          return [_c("div", {
            staticClass: "flex"
          }, _vm._l(_vm.detailData[item.key], function (Itm, Idx) {
            return _c("a-button", {
              key: Idx
            }, [_vm._v(_vm._s(Itm))]);
          }), 1)];
        },
        proxy: true
      }], null, true)
    }) : _c("cardItem", {
      attrs: {
        label: item.label,
        value: _vm.detailData[item.key]
      }
    })], 1);
  }), 0), _c("ul", _vm._l(_vm.columnsThree, function (item) {
    return _c("li", {
      key: item.key
    }, [_c("cardItem", {
      attrs: {
        label: item.label,
        value: _vm.detailData[item.key]
      }
    })], 1);
  }), 0)])]), _c("div", {
    staticClass: "module"
  }, [_c("p", {
    staticClass: "title pl-20"
  }, [_vm._v("群管理")]), _c("a-row", {
    staticClass: "pl-20"
  }, [_c("p", {
    staticClass: "form"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("群标识：")]), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: !_vm.isEdit,
      showSearch: "",
      allowClear: "",
      "filter-option": _vm.filterOption,
      placeholder: "选择标识"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.sign,
      callback: function callback($$v) {
        _vm.sign = $$v;
      },
      expression: "sign"
    }
  }, _vm._l(_vm.optionsIcons, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)])], 1), _c("a-button", {
    staticClass: "back",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")]), _vm.isEdit ? [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["im:group:save"],
      expression: "['im:group:save']"
    }],
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")])] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };