var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "item-label"
  }, [_vm._v(_vm._s(_vm.label))]), _c("div", {
    staticClass: "item-value"
  }, [_vm.$slots.value ? _vm._t("value") : _c("span", {
    staticClass: "ellipsis-5"
  }, [_vm._v(" " + _vm._s(_vm.value || "---"))])], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };