import _defineProperty from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import { IMApi } from '@/api';
import cardItem from './components/cardItem';
export default {
  data: function data() {
    return _defineProperty({
      isEdit: false,
      editId: '',
      columns: [{
        key: 'headimgurl',
        label: '群头像'
      }, {
        key: 'name',
        label: '群名称'
      }, {
        key: 'id',
        label: 'ID'
      }, {
        key: 'sendbirdid',
        label: 'sendbird ID'
      }, {
        key: 'createtime',
        label: '创建时间'
      }],
      columnsTwo: [{
        key: 'ownerid',
        label: '群主UID'
      }, {
        key: 'ownername',
        label: '群主昵称'
      }, {
        key: 'iconname',
        label: '群标识名'
      }, {
        key: 'recommendswitch',
        label: '是否可搜索'
      }, {
        key: 'description',
        label: '群简介'
      }, {
        key: 'labels',
        label: '群标签'
      }],
      columnsThree: [{
        key: 'num',
        label: '群规模'
      }, {
        key: 'activenum',
        label: '七日均日活'
      }, {
        key: 'messagesnum',
        label: '七日日均发言人数'
      }],
      detailData: {},
      loading: false,
      dataSource: [],
      sign: null,
      optionsIcons: []
    }, "loading", false);
  },
  components: {
    cardItem: cardItem
  },
  mounted: function mounted() {
    if (this.$route.query.type == 'view') {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }
    this.editId = this.$route.query.id || '';
    this.getIcons();
    this.getDetail();
  },
  methods: {
    handleChange: function handleChange(val) {
      console.log(val);
    },
    getDetail: function getDetail() {
      var _this = this;
      IMApi.channeldetail({
        id: this.editId
      }).then(function (res) {
        // console.log(res);
        _this.detailData = res;
        _this.sign = res.iconid;
      }).catch(function (err) {});
    },
    getIcons: function getIcons() {
      var _this2 = this;
      IMApi.iconlist().then(function (res) {
        // console.log(res);
        _this2.optionsIcons = res.filter(function (val) {
          return val.status;
        }) || [];
      }).catch(function (err) {});
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.propsData.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    save: function save() {
      var _this3 = this;
      this.loading = true;
      IMApi.channeledit({
        id: this.editId,
        iconId: this.sign
      }).then(function (res) {
        _this3.$message.success('保存成功');
        _this3.$router.push('/im/index');
      }).catch(function (err) {}).finally(function () {
        _this3.loading = false;
      });
    }
  }
};